import { render, staticRenderFns } from "./DonationsTable.vue?vue&type=template&id=13009d60&scoped=true"
import script from "./DonationsTable.vue?vue&type=script&lang=js"
export * from "./DonationsTable.vue?vue&type=script&lang=js"
import style0 from "./DonationsTable.vue?vue&type=style&index=0&id=13009d60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13009d60",
  null
  
)

export default component.exports