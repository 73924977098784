<template>
  <div>
    <div style="height: 100%;">
      <form v-if="local_form && local_form.texts">
        <!-- Tabs -->
        <div class="tabs">
          <ul class="tab-list">
            <li :class="{ active: activeTab === 0 }" @click="activeTab = 0">{{ $t('DONATION.BASIC') }}</li>
            <li :class="{ active: activeTab === 1 }" @click="activeTab = 1">{{ $t('DONATION.ADVANCED') }}</li>
          </ul>
          <div class="tab-content">
            <!-- Basic Tab Content -->
            <div v-show="activeTab === 0">
              
              <div class="ml-row mt-4">
                <div class="ml-col">
                  <h4>{{ $t('DONATION.DIRECT_LINK') }}</h4>
                  <p>{{ $t('DONATION.DIRECT_LINK_INFO') }}</p>
                  
                  <p v-if="!local_form.id">...</p>
                  <p v-else><a @click.prevent="open_link" href="#">{{ direct_link_url }}</a></p>

                </div>
              </div>

              <div class="ml-row mt-4">
                <div class="ml-col">
                  <h4>{{ $t('DONATION.NAME') }}</h4>
                  <p>{{ $t('DONATION.NAME_INFO') }}</p>
                  <memlist-text-input
                    id="name"
                    name="name"
                    title=""
                    layout="vertical"
                    validation_type="TEXT"
                    v-model="donation_page.name"
                    :validate="force_validate"
                    :placeholder="$t('DONATION.NAME')"
                    :required="false"
                    :invalid_text="$t('DONATION.NAME_REQUIRED')"
                    @validated="validated"
                  />
                </div>

              </div>
              <div class="ml-row mt-12">
                <div class="ml-col">
                  <h4>{{ $t('DONATION.HOW_TO') }}</h4>
                  <p>{{ $t('DONATION.HOW_TO_INFO') }}</p>
                  <memlist-checkbox 
                    :text="$t('DONATION.ACTIVATE_ONE_TIME_DONATIONS')"
                    v-model="donation_page.fields.activate_one_time"
                  />
                  <memlist-checkbox 
                    :text="$t('DONATION.ACTIVATE_MONTHLY_DONATIONS')"
                    v-model="donation_page.fields.activate_subscription"
                  />
                </div>
              </div>
              <div class="ml-row mt-12">
                <div class="ml-col">
                  <h4>{{ $t('DONATION.DONATION_ITEMS') }}</h4>
                  <p>{{ $t('DONATION.DONATION_ITEMS_INFO') }}</p>
                  <DonationItemRow
                    v-for="(prop, index) in donation_page.fields.items"
                    :key="index"
                    :item="prop"
                    @delete="delete_item"
                    @updated="updated_item"
                    />
                  <p class="mt-4"><a @click.prevent="add_row" href="#">{{ $t('DONATION.ADD_ROW') }}</a></p>
                </div>
              </div>
              
            </div>
            <!-- Advanced Tab Content -->
            <div v-show="activeTab === 1">

              <div class="ml-row mt-4">
                <div class="ml-col">
                  <h4>{{ $t('DONATION.SHOP_ITEM') }}</h4>
                  <p>{{ $t('DONATION.SHOP_ITEM_INFO') }}</p>
                  
                  <memlist-select-input2
                    id="shop_item_id"
                    name="shop_item_id"
                    :title="''"
                    layout="vertical"
                    validation_type="TEXT"
                    v-model="shop_item_id"
                    :items="shop_item_options"
                    :validate="force_validate"
                    :placeholder="$t('DONATION.SHOP_ITEM')"
                    :required="false"
                    :invalid_text="''"
                    :info_text="''"
                    @validated="validated"
                  />

                </div>
              </div>

              <div class="ml-row mt-12">
                <div class="ml-col">

                  <memlist-checkbox 
                    :text="$t('DONATION.ACTIVATE_PERSON_DETAILS_PAGE')"
                    v-model="activate_person_details_page"
                  />
                  

                  <!-- Personal details -->
                  <FormPagePersonDetailsEditor
                    v-if="activate_person_details_page"
                    :item="person_details_page"
                    :validate="force_validate"
                    @change="change_person_details"
                  />
                </div>
              </div>

              <div class="ml-row mt-12">
                <div class="ml-col">

                  <memlist-checkbox 
                    :text="$t('DONATION.ACTIVATE_CONFIRM_PAGE')"
                    v-model="activate_confirm_page"
                  />
                  
                  
                  <!-- Confirm editor -->
                  <FormPageConfirmEditor
                     v-if="activate_confirm_page"
                    :item="confirm_page"
                    :validate="force_validate"
                    @change="change_confirm"
                  />

                </div>
              </div>

              <div class="ml-row mt-12">
                <div class="ml-col">
                  <p style="font-weight: bold;">{{ $t('FORM.STYLE.BG') }}</p>
                  <color-picker-input id="field-bg" v-model="local_form.style.bg" class="mr-4 mb-12"/>
                </div>
                <div class="ml-col">
                  <p style="font-weight: bold;">{{ $t('FORM.STYLE.PRIMARY_COLOR') }}</p>
                  <color-picker-input id="field-primary_color" v-model="local_form.style.primary_color" class="mr-4 mb-12"/>
                </div>
              </div>

              <div class="ml-row mt-12">
                <div class="ml-col">
                  <p style="font-weight: bold;">{{ $t('FORM.STYLE.HOVER_COLOR') }}</p>
                  <color-picker-input id="field-hover_color" v-model="local_form.style.hover_color" class="mr-4 mb-12"/>
                </div>
                <div class="ml-col">
                  <p style="font-weight: bold;">{{ $t('FORM.STYLE.SECONDARY_COLOR') }}</p>
                  <color-picker-input id="field-secondary_color" v-model="local_form.style.secondary_color" class="mr-4 mb-12"/>
                </div>
              </div>

              <div class="ml-row">
                <div class="ml-col">
                  <h5 :style="header_style" class="mt-4 mb-4">{{ $t('DONATION.TEST_HEADER') }}</h5>
                    <div class="custom-container">
                      <memlist-circular-button
                        class="fill-button-width"
                        v-if="local_form && local_form.style"
                        :text="'Donera'"
                        :primary_color="local_form?.style?.primary_color"
                        :secondary_color="local_form?.style?.secondary_color"
                        :hover_color="local_form?.style?.hover_color"
                      />
                      
                    </div>
                </div>
              </div>

              
              <div class="ml-row mt-12">
                <div class="ml-col">
                  <p style="font-weight: bold;">{{ $t('DONATION.CODE_INTEGRATION') }}</p>
                  <p>{{ $t('DONATION.CODE_INTEGRATION_INFO') }}</p>
                  <textarea
                    v-model="code_integration_iframe"
                    class="ml-textarea"
                    :disabled="true"
                    style="font-family: monospace; height: 88px;"
                  >

                  </textarea>
                </div>
              </div>

              <div class="ml-row mt-12">
                <div class="ml-col">
                  <p style="font-weight: bold;">{{ $t('DONATION.CODE_INTEGRATION_BUTTON') }}</p>
                  <p>{{ $t('DONATION.CODE_INTEGRATION_BUTTON_INFO') }}</p>
                  <textarea
                    v-model="code_integration_button"
                    class="ml-textarea"
                    :disabled="true"
                    style="font-family: monospace; height: 88px;"
                    rows="2"
                  >

                  </textarea>
                </div>
              </div>
              

            </div>
          </div>
        </div>
        <!-- Error Popup -->
        <ErrorPopup :error="error_popup_message" />
        <!-- Save and Close Buttons -->
        <div class="ml-row mt-4">
          <div class="ml-col">
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_save_clicked"
              @close="$emit('cancel')"
              ref="saveButton" />
          </div>
        </div>
      </form>
    </div>
  </div>

</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

import ErrorPopup from '@/view/components/ErrorPopup.vue';
import { get_domain_url, downloadWithAxios } from '@/core/services/fileDownload';
import ColorPickerInput from '@/view/components/ColorPickerInput';


import FormPagePersonDetailsEditor from '@/view/pages/ml/forms/pages/FormPagePersonDetailsEditor.vue';
import FormPageConfirmEditor from '@/view/pages/ml/forms/pages/FormPageConfirmEditor.vue';
import DonationItemRow from './DonationItemRow.vue';

export default {

  name: 'DonationEditor',

  props: ['item'],
  emits: ['updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    FormPagePersonDetailsEditor,
    FormPageConfirmEditor,
    DonationItemRow,
    ErrorPopup,
    ColorPickerInput,
  },

  watch: {

    async activate_person_details_page() {
      if (!this.activate_person_details_page) {
        if (this.person_details_page) {
          this.delete_form_page(this.person_details_page.id);
          this.person_details_page = null;
        }
      }
      else {
        if (!this.person_details_page && this.local_form.id) {
          this.person_details_page = await this.create_form_page({
            name: 'details',
            type: 'PERSON_DETAILS',
            index: 1,
            fields: {}
          });
        }
      }
      
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        let refresh_stats = false;

        if (!this.local_form || this.local_form.id !== this.item.id) {
          refresh_stats = true;
        }

        this.local_form = { ...this.item };

        if (!this.local_form.pages) {
          this.local_form.pages = [];
        }

        const donation_page = this.local_form.pages.find(item => item.type === 'DONATION');
        const confirm_page = this.local_form.pages.find(item => item.type === 'CONFIRM');
        const person_details_page = this.local_form.pages.find(item => item.type === 'PERSON_DETAILS');

        if (donation_page) {
          this.donation_page = donation_page;

          this.shop_item_id = this.donation_page.shop_item_ids[0];
        }

        if (confirm_page) {
          this.confirm_page = confirm_page;
          this.activate_confirm_page = true;
        }
        
        if (person_details_page) {
          this.person_details_page = person_details_page;
          this.activate_person_details_page = true;
        }


        if (!this.local_form.linkstr) {
          this.local_form.linkstr = 'not-finished';
        }

        if (!this.local_form.style) {
          this.local_form.style = {
            bg: '#ffffff'
          };
        }
        else {
          if (!this.local_form.style.bg) {
            this.local_form.style.bg = '#ffffff';
          }
        }
        
        if (!this.donation_page.fields.items || this.donation_page.fields.items.length === 0) {
          this.donation_page.fields.items = [
            { id: 1, type: 'FIXED', amount: 100 }
          ];
        }
        
        if (!this.donation_page.shop_item_ids) {
          this.donation_page.shop_item_ids = [];
        }
      },
      immediate: true,
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies']),

    header_style() {
      return {
        width: '100%',
        color: this.local_form?.style?.primary_color || 'black'
      }
    },

    code_integration_iframe() {
      return `<iframe style="width: 320px; height: 640px;" src="${get_domain_url()}/iframe/donation/full/${this.local_form.linkstr}"></iframe>`;
    },

    code_integration_button() {
      return `<iframe style="width: 240px; height: 80px;" src="${get_domain_url()}/test/iframe/donation/button/${this.local_form.linkstr}"></iframe>`;
    },
    
    direct_link_url() {
      return `${get_domain_url()}/test/iframe/donation/button/${this.local_form.linkstr}`;
    },

    type_options() {
      return [
        { text: this.$t('FORM.TYPES.REGISTER_MEMBER'), value: 'REGISTER_MEMBER' },
        { text: this.$t('FORM.TYPES.RENEW_MEMBER'), value: 'RENEW_MEMBER' },
        { text: this.$t('FORM.TYPES.COMPANY'), value: 'COMPANY' },
        { text: this.$t('FORM.TYPES.EVENT'), value: 'EVENT' }
      ]
    },
    link_url() {
      if (this.local_form.id && this.local_form.linkstr.length > 0) {
        return `${get_domain_url()}/form/${this.local_form.linkstr}`;
      }
    }
  },

  async mounted() {

    this.load_shop_items();
  },

  methods: {

    updated_item(item) {

      console.log('item', item);
      
      const ii = this.donation_page.fields.items.find(i => i.id === item.id);

      if (ii) {
        ii.amount = item.amount;
        ii.min_amount = item.min_amount;
        ii.type = item.type;
      }
      else {
        console.error('item not found', item);
      }
      
    },

    async load_shop_items() {
      try {
        const res = await axios.get(`/shop_item/admin/${this.currentCompanyId}`);

        this.shop_item_options = [];

        if (res.status === 200) {
          this.shop_item_options = res.data.filter(item => item.is_donation).map(item => ({ text: item.name, value: item.shop_item_id }));
        }
      }
      catch (err) {
        console.error('load_shop_items error', err);
      }
    },

    change_person_details(item) {
      console.log('change_person_details', item);

      if (this.person_details_page) {
        this.person_details_page.fields = item.fields;
      }
    },

    delete_item(item) {
      this.donation_page.fields.items = this.donation_page.fields.items.filter(i => i.id !== item.id);
    },

    add_row() {

      let max_id = 0;

      for (const item of this.donation_page.fields.items) {
        max_id = item.id > max_id ? item.id : max_id;
      }

      this.donation_page.fields.items.push({
        id: max_id + 1,
        type: 'FIXED',
        amount: 100
      });

      this.donation_page = { ...this.donation_page };
    },
    
    create_form_rule_clicked() {

      this.selected_rule = {};

      this.$nextTick(()=>{
        this.$refs['form-rule-editor-modal'].show();
      });
    },

    async delete_form_rule_clicked(id) {
      try {
        const response = await axios.delete(`/form/rule/${id}`);

        if (response.status === 204) {
          this.local_form.form_rules = this.local_form.form_rules.filter(p => p.id !== id);

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.FORM_RULE_DELETED'));

          return;
        }
      }
      catch (err) {
        console.error('delete_form_rule_clicked', err);
      }
      },

    async select_form_rule_clicked(id) {

      const res = await axios.get(`/form/rule/${id}`);

      if (res.status === 200) {
        this.selected_rule = res.data;

        console.log('selected_rule', this.selected_rule);

        this.$nextTick(()=>{
          this.$refs['form-rule-editor-modal'].show();
        });
      }
    },

    async get_shop_items_for_companies(company_ids) {
      if (!company_ids || company_ids.length === 0) {
        return [];
      }

      try {
        const res = await axios.post(`/shop_item/list/companies`, company_ids);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_shop_items_for_company', err);
      }

      return [];
    },

    form_rule_created(rule) {
      this.local_form.form_rules.push(rule);

      this.local_form = { ...this.local_form };
    },

    async create_form_rule(item) {
      try {
        const response = await axios.post(`/form/${this.local_form.id}/rule`, item);

        if (response.status === 201) {
          this.local_form.form_rules.push(response.data);

          this.local_form = { ...this.local_form };

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.FORM_RULE_CREATED'));

          return;
        }
      }
      catch (err) {
        console.error('create_form_rule', err);
      }
    },

    async update_form_rule(item) {
      try {

        const response = await axios.put(`/form/rule/${item.id}`, item);

        if (response.status === 200) {
          const index = this.local_form.form_rules.findIndex(p => p.id === item.id);

          this.local_form.form_rules[index] = response.data;
          this.local_form.form_rules = [...this.local_form.form_rules];

          this.local_form = { ...this.local_form };

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.FORM_RULE_UPDATED'));

          return;
        }
      }
      catch (err) {
        console.error('update_form_rule', err);
      }
    },

    /// in some cases the child form has created its own page
    form_page_created(page) {
      this.local_form.pages.push(page);

      this.local_form = { ...this.local_form };
    },

    async update_form_page(item) {
      try {

        if (!item.texts) {
          item.texts = undefined;
        }
        if (!item.fields) {
          item.fields = undefined;
        }
        if (!item.company_hierarchy) {
          item.company_hierarchy = undefined;
        }

        item.shop_item_ids = [this.shop_item_id];

        const response = await axios.put(`/form/page/${item.id}`, item);

        if (response.status === 200) {
          const index = this.local_form.pages.findIndex(p => p.id === item.id);

          this.local_form.pages[index] = response.data;
          this.local_form.pages = [...this.local_form.pages];

          this.local_form = { ...this.local_form };

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_UPDATED'));

          return;
        }
      }
      catch (err) {
        console.error('update_form_page', err);
      }
    },

    async update_form() {

      try {
        
        console.log('updating form');

        this.local_form.name = this.donation_page.name;
        
        await this.put_form(this.local_form);

        await this.update_form_page(this.donation_page);

        if (this.activate_confirm_page) {
          if (this.confirm_page.id) {
            await this.update_form_page(this.confirm_page);
          }
          else {
            await this.update_form_page(this.confirm_page);
          }
        }
        else {
          if (this.confirm_page && this.confirm_page.id) {
            await this.delete_form_page_clicked(this.confirm_page.id);
          } 
        }

        if (this.activate_person_details_page) {
          if (this.person_details_page.id) {
            await this.update_form_page(this.person_details_page);
          }
          else {
            await this.update_form_page(this.person_details_page);
          }
        }
        else {
          if (this.person_details_page && this.person_details_page.id) {
            await this.delete_form_page_clicked(this.person_details_page.id);
          } 
        }

        this.local_form = { ...this.local_form };

        this.$emit('updated', this.local_form);
      }
      catch (err) {
        console.error('update_form error', err);
      }

    },

    validated(field_name, value, valid) {
      this.local_form[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_save_clicked() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(async ()=>{

        try {
          if (this.is_form_valid) {
            this.spinning = false;

            if (this.local_form.id) {
              await this.update_form();  
            }
            else {
              await this.post_form(this.local_form);  
            }
            
          }
          else {
            this.show_error = true; 
          }
        }
        catch (err) {
          console.error('on_save_clicked', err);
        }

        setTimeout(()=>{
          this.spinning = false;

          this.$refs['saveButton'].stop();
        }, 500);
        
      });
    },

    async post_form(item) {
      try {

        item.company_id = this.currentCompanyId;
        item.name = this.donation_page.name;
        item.type = 'DONATION';

        const response = await axios.post('/form', item);

        if (response.status === 201) {

          this.local_form = { ...response.data };
          this.local_form.pages = [];

          this.create_form_page(this.donation_page);

          if (this.activate_confirm_page) {
            this.create_form_page(this.confirm_page);
          }

          if (this.activate_person_details_page) {
            this.create_form_page(this.person_details_page);
          }

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.CREATED'));

          this.$emit('created', response.data);

          return;
        }
      }
      catch (err) {
        console.error('post_form', err);
      }
    },

    async put_form(item) {
      try {
        const response = await axios.put(`/form/${item.id}`, item);

        if (response.status === 204) {
          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.UPDATED'));

          this.$emit('updated', item);

          return;
        }
      }
      catch (err) {
        console.error('put_form', err);
      }
    },

    async create_form_page(item) {
      try {
        const response = await axios.post(`/form/${this.local_form.id}/page`, item);

        if (response.status === 201) {
          this.local_form.pages.push(response.data);

          this.local_form = { ...this.local_form };

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_CREATED'));

          return response.data;
        }
      }
      catch (err) {
        console.error('create_form_page', err);
      }

      throw 'unable to create';
    },

    async delete_form_page(id) {
      const res = await axios.delete(`/form/page/${id}`);

      if (res.status === 204) {
        this.local_form.pages = this.local_form.pages.filter(item => item.id !== id);
        this.local_form = { ...this.local_form };

        this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_DELETED'));
      }
      
    },

    create_form_page_clicked() {

      this.selected_page = {};

      this.$nextTick(()=>{
        this.$refs['form-page-editor-modal'].show();
      });
    },

    async delete_form_page_clicked(id) {
      try {
        const response = await axios.delete(`/form/page/${id}`);

        if (response.status === 204) {
          this.local_form.pages = this.local_form.pages.filter(p => p.id !== id);

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_DELETED'));

          return;
        }
      }
      catch (err) {
        console.error('delete_form_page_clicked', err);
      }
    },

    async select_form_page_clicked(id) {

      const res = await axios.get(`/form/page/${id}`);

      if (res.status === 200) {
        this.selected_page = res.data;

        this.$nextTick(()=>{
          this.$refs['form-page-editor-modal'].show();
        });
      }

    },

    async get_stats_for_form(form_id) {

      if (!form_id) {
        return;
      }

      try {
        const res1 = await axios.get(`/form/stats/${form_id}/totals?from=${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}&to=${dayjs().format('YYYY-MM-DD')}`);

        this.stats = res1.data;

        const res2 = await axios.get(`/form/stats/${form_id}?from=${dayjs().subtract(30, 'days').format('YYYY-MM-DD')}&to=${dayjs().format('YYYY-MM-DD')}`);

        this.visitor_stats = res2.data;
      }
      catch (err) {
        console.error('get_stats_for_form', err);
      }
    }

  },


  data() {
    return {

      shop_item_options: [],

      activate_person_details_page: false,
      activate_confirm_page: false,

      activeTab: 0,

      selected_rule: {},

      visitor_stats: [],

      // stored as a ml_form_page
      donation_page: {
        type: 'DONATION',
        name: 'header',
        index: 0,
        fields: {
          items: [], /// { id: number, type: 'FIXED|CUSTOM', amount: undefined | number, max_amount: undefined | number }
          activate_one_time: true,
          activate_subscription: false,
        } 
      },

      // stored as ml_form_page
      person_details_page: null,

      confirm_page: {
        type: 'CONFIRM',
        index: 2,
        fields: {}
      },

      stats: {
        first: 0,
        done_error: 0,
        done_ok: 0,
      },

      error_popup_message: null,

      local_form: {
        type: null,
        activate_one_time: true,
        activate_subscription: false,
      },

      selected_page: {},

      shop_items: [],

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";


.fill-button-width {
  height: 46px;
  min-width: 140px;
}

</style>
