<template>

  <div class="donation-item-row">

    <div class="wide-col">
      <memlist-select-input2
          id="type"
          name="type"
          :title="$t('DONATION.ITEM_TYPE')"
          layout="vertical"
          validation_type="TEXT"
          v-model="local_item.type"
          :items="type_options"
          :validate="force_validate"
          :placeholder="$t('DONATION.ITEM_TYPE')"
          :required="false"
          :invalid_text="''"
          :info_text="''"
          @validated="validated"
        />
    </div>
  <div class="wide-col">
    
    <div v-if="local_item.type === 'FIXED'">

    <memlist-text-input
      id="amount"
      name="amount"
      :title="$t('DONATION.FIXED_AMOUNT')"
      layout="vertical"
      validation_type="TEXT"
      v-model="local_item.amount"
      :validate="force_validate"
      :placeholder="$t('DONATION.FIXED_AMOUNT')"
      :required="false"
      @validated="validated"
    >
    </memlist-text-input>

    </div>
    <div v-else-if="local_item.type === 'CUSTOM'">
      <memlist-text-input
        id="min_amount"
        name="min_amount"
        :title="$t('DONATION.MIN_AMOUNT')"
        layout="vertical"
        validation_type="TEXT"
        v-model="local_item.min_amount"
        :validate="force_validate"
        :placeholder="$t('DONATION.MIN_AMOUNT')"
        :required="false"
        @validated="validated"
      >
      </memlist-text-input>
      </div>

    </div>
    <div class="fixed-col" style="margin-top: 36px;">
      <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_item_clicked">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
        </span>
      </a>
    </div>
  </div>
</template>


<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

export default {
  name: 'DonationItemRow',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {
      local_item: Object.assign({}, this.item),
      type_options: [
        { value: 'FIXED', text: this.$t('DONATION.TYPE_FIXED') },
        { value: 'CUSTOM', text: this.$t('DONATION.TYPE_CUSTOM') },
      ],
      force_validate: false,
    };
  },
  watch: {
    item: {
      handler(newVal) {
        this.local_item = Object.assign({}, newVal);
      },
      deep: true,
    },
    local_item: {
      handler(newVal) {
        console.log('local item updated');
        this.$emit('updated', newVal);
      },
      deep: true,
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {
    validated() {
      // Handle validation logic if necessary
    },
    delete_item_clicked() {
      this.$emit('delete', this.local_item);
    },
  }
};
</script>
<style scoped>

.donation-item-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.wide-col {
  margin-right: 10px;
}

.fixed-col {
  flex: 0 0 auto;
}

</style>